<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientrr" x1="1" x2="0.5" y2="1">
     <stop offset="0" stop-color="#179dff"/>
     <stop offset="1" stop-color="#453eff"/>
    </linearGradient>
   </defs>
   <g id="Facebook" transform="translate(-130 -288)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(130 288)" width="60"/>
    <path d="M16.624,16.875l.833-5.429h-5.21V7.922a2.715,2.715,0,0,1,3.061-2.933h2.368V.367A28.882,28.882,0,0,0,13.473,0C9.183,0,6.378,2.6,6.378,7.308v4.138H1.609v5.429H6.378V30h5.869V16.875Z" data-name="Icon awesome-facebook-f" id="Icon_awesome-facebook-f" style="fill: url(#linear-gradientrr);" transform="translate(150.391 303)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>